@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

html, body {
	min-height: 100%;
}

body {
	font-family: 'Roboto', sans-serif;
	font-size: 15px;
	color: #000;
	padding: 65px 0 0 250px;
	background: #000 url(../img/bg-main.jpg) no-repeat center center / cover;
}

img {
	max-width: 100%;
}

a {
	text-decoration: none;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

section {
	padding: 20px 0;
}

section .sectionHeading {
	font-size: 30px;
	margin-bottom: 5px;
	font-weight: 700;
}

section .sectionSubHeading {
	font-size: 15px;
	margin-bottom: 20px;
	font-weight: 400;
}

section .sectionSubHeading .more {
	display: inline-block;
	border: 1px solid #98795C;
	color: #98795C;
	padding: 5px 15px;
	border-radius: 30px;
	font-size: 14px;
	font-weight: 700;
	line-height: 1;
}

.form-control {
	font-size: 14px;
	border-radius: 5px;
}

.btn {
	border: none;
	border-radius: 5px;
	padding: 10px 25px;
	color: #fff;
}

.btn:hover {
	color: #fff;
}

.blue-cyan {
	color: #fff;
	background: #68cbcb;
}

.blue-cyan.opacity {
	background: rgb(12 161 243 / 80%);
}

.dark-blue {
	background: #004c76;
}

.red-pink {
	background: #ff2b2b;
}

.amber {
	background: #ff6f00
}

.green-teal {
	background: #43a047;
}

.purple-amber {
	background: #d500f9;
}

.purple-deep {
	background: #7b1fa2;
}

.orange-deep {
	background: #bf360c;
}

.indigo-light-blue {
	background: #3949ab;
}

#mainContent {
	padding: 20px;
	height: 100%;
	border-left: 1px solid #EBE8E2;
}

/*========================== HEADER ==========================*/
header {
	position: fixed;
	width: 100%;
	left: 0;
	top: 0;
	padding: 10px;
	height: 65px;
	background: rgb(103 203 203 / 30%);
}

header .btn {
	box-shadow: none;
	font-weight: 700;
}

/*========================== SIDE MENU ==========================*/
aside {
	position: fixed;
	width: 250px;
	height: 100%;
	left: 0;
	top: 0;
	background: #68cbcb;
	z-index: 2;
}

aside #logo {
	background: rgb(0 0 0 / 25%);
	padding: 10px;
	color: #fff;
	font-weight: 700;
	font-size: 16px;
}

aside #logo img {
	width: 45px;
}

aside .mainmenu {
	margin-top: 10px;
	height: calc(100% - 65px);
	margin-bottom: 0;
	padding-bottom: 20px;
}

aside .mainmenu li a {
	display: block;
	color: #fff;
	padding: 10px 15px;
	border-radius: 8px;
	margin: 0 10px;
}

aside .mainmenu li a.active {
	color: #fff;
}

aside .mainmenu li a.active:hover {
	color: #fff;
	background: rgb(0 0 0 / 20%);
}

aside .mainmenu li a:hover {
	background: rgba(0, 0, 0, .035);
}

aside .mainmenu li a .icon {
	width: 24px;
	height: 24px;
	margin-right: 10px;
}

/*========================== HOMEPAGE ==========================*/
#content {
	padding: 20px 20px 80px;
}

.overview .inner {
	color: #000;
	padding: 30px;
	height: 100%;
	border-radius: 8px;
	font-weight: 300;
}

.overview .title {
	font-size: 25px;
	line-height: 1.5;
	font-weight: 300;
	min-height: 75px;
}

.overview .desc {
	font-size: 16px;
	margin: 20px 0 50px;
	min-height: 50px;
}

.staking .item {
	border-radius: 15px;
	padding: 30px;
}

.staking .tokenHeader {
	margin-bottom: 30px;
}

.staking .tokenHeader .name {
	font-size: 20px;
}

.staking .tokenHeader .rate {
	display: inline-block;
	border: 1px solid #fff;
	border-radius: 10px;
	padding: 3px 10px;
	line-height: 100%;
}

.staking .text {
	font-size: 16px;
	text-transform: uppercase;
}

.staking .number {
	font-size: 18px;
	font-weight: 700;
}

.staking .detail {
	text-align: center;
	margin-top: 20px;
}

/*========================== FOOTER ==========================*/
footer {
	color: #fff;
	position: fixed;
	right: 0;
	bottom: 0;
	padding: 10px;
	width: calc(100% - 250px);
	text-align: right;
	background: rgb(103 203 203 / 30%);
}

footer .social a {
	display: inline-block;
	padding: 8px;
	margin: 0 3px;
	width: 40px;
	border-radius: 5px;
}

/*============================ ADMIN =============================*/

.dashboard .row {
	--bs-gutter-x: 0rem;
}

.dashboard .btn {
	padding: 5px 10px;
	height: 35px;
}
.dashboard .inner {
	padding: 20px;
	margin-right: 5px;
	background-color: #357286db;
}

/* =========================   LOADING   ========================= */
.pageLoading {
	color: #ff6f00;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,.7);
	z-index: 3;
}

/*========================== RESPONSIVE ==========================*/
@media (max-width: 1440px) {}

@media (max-width: 1366px) {}

@media (max-width: 1024px) {}

@media (max-width: 992px) {}

@media (max-width: 768px) {}

@media (max-width: 576px) {}